.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Затемнение фона */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.modal-content {
  background-color: rgb(87, 87, 87);
  padding: 20px;
  border-radius: 10px;
  max-width: 80%;
  max-height: 80%;
  overflow-y: auto;
  position: relative;
}

.close {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.image-gallery {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.image-gallery-2 {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.image-gallery img {
  margin: 10px;
  transition: transform 0.2s;
}

.image-gallery img:hover {
  transform: scale(1.3);
}

.dlt{
  opacity: 0.5;
  position: absolute;
  text-decoration: none;
}

.dlt:hover{
  opacity: 1;
}

.link-for-text{
  color: white;
  width: 200px;
  text-decoration: none;
  opacity: 0.7;
}

.link-for-text:hover{
  opacity: 1;
}
